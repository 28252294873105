import { Component } from 'react'
import './home.css'

class Home extends Component {

    render() {
        return (
            <div style={{ height: '100vh', width: '100%', backgroundImage: 'linear-gradient(to bottom right, white, navy)' }}>
                <div style={{ width: '100%', fontSize: '48pt', paddingTop: '40%', textAlign: 'center', fontWeight: 'bold', color: '#fff' }}>Coming Soon</div>                
            </div>
        )
    }
}

export default Home