import React, { Component } from 'react'
import './devices.css'
import { API } from 'aws-amplify'
import { FaArrowCircleDown, FaArrowAltCircleUp, FaTrashAlt } from 'react-icons/fa'

const canvasWidth = 350
const canvasHeight = 350
const outsideRadius = canvasWidth / 2 - 5
const insideRadius = 5
let textRadius = (13 / 32 * canvasWidth)
let startAngle = 0;
let arc = Math.PI / 1;


class Devices extends Component {
    constructor(props) {
        super(props)
        this.canvasRef = React.createRef()
        this.state = {
            width: 0,
            height: 0,
            displayFull: true,
            displaySmall: false,
            displayMedium: false,
            displayConfirm: false,
            devices: [],
            sel: -1,
            deviceId: ''
        }
        this.updateWindowSize = this.updateWindowSize.bind(this)
    }

    componentDidMount() {
        if (!this.props.isAuthenticated || !this.props.currUser) {
            this.props.history.push('/')
            return false
        }
        API.get('tc', 'devices')
            .then(devices => this.setState({ devices }))
            .catch(err => console.log(err))
        this.updateWindowSize()
        window.addEventListener('resize', this.updateWindowSize)
        if (this.props.deviceId) this.setState({ deviceId: this.props.deviceId })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowSize)
    }

    updateWindowSize() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            displayFull: window.innerWidth > 1155,
            displaySmall: window.innerWidth < 650,
            displayMedium: window.innerWidth < 760 && window.innerWidth >= 650
        })
    }

    handleTextInput(event) {
        if (event.target.name.indexOf('device-') === 0) {
            let device = { ...this.state.devices[this.state.sel] }
            device[event.target.name.split('-')[1]][parseInt(event.target.name.split('-')[2])] = event.target.value
            let devices = [...this.state.devices]
            devices[this.state.sel] = device
            this.setState({ devices })
            this.drawWheel()
        } else this.setState({ [event.target.name]: event.target.value })
    }

    handleAddDevice(id) {
        API.post('tc', `devices/${id}`)
            .then(resp => this.setState({ devices: [...this.state.devices, resp] }))
    }

    handleDelDevice(idx) {
        if (this.state.displayConfirm) {
            API.del('tc', `devices/${this.state.devices[this.state.sel].deviceId}`)
                .then(resp => this.setState({ sel: null, displayConfirm: false, devices: [...this.state.devices.splice(this.state.sel, 1)] }))
                .catch(err => console.log(err))
        } else {
            if (idx === this.state.sel) {
                this.setState({ displayConfirm: true })
            } else this.setState({ sel: idx })
        }
    }

    handleCancelDelDevice() {
        this.setState({ displayConfirm: false })
    }

    handleDeviceSel(sel) {
        this.setState({ sel })
        if (this.state.devices[sel].colors && this.state.devices[sel].colors.length > 0 && this.state.devices[sel].vals && this.state.devices[sel].vals.length > 0) {
            this.drawWheel(sel)
        } else {
            const canvas = this.canvasRef.current
            const ctx = canvas.getContext('2d')
            ctx.clearRect(0, 0, 400, 400)
        }
    }

    handleAddConfig() {
        if (this.state.sel > -1) {
            const device = { ...this.state.devices[this.state.sel] }
            device.colors.push('')
            device.vals.push('')
            let devices = [...this.state.devices]
            devices[this.state.sel] = device
            this.setState({ devices })
        }
    }

    handleValueDelete(idx) {
        let device = { ...this.state.devices[this.state.sel] }
        device.colors.splice(idx, 1)
        device.vals.splice(idx, 1)
        let devices = [...this.state.devices]
        devices[this.state.sel] = device
        this.setState({ devices })
        this.drawWheel()
    }

    handleSaveConfig() {
        API.put('tc', `devices/${this.state.devices[this.state.sel].deviceId}`, { body: { vals: this.state.devices[this.state.sel].vals, colors: this.state.devices[this.state.sel].colors } })
            .then(result => console.log(result))
            .catch(err => console.log(err))
    }

    handleMove(dir, idx) {
        if (!(dir === 'up' && idx === 0) && !(dir === 'down' && idx === this.state.devices[this.state.sel].vals.length - 1)) {
            let device = { ...this.state.devices[this.state.sel] }
            device.vals.splice(dir === 'up' ? idx - 1 : idx + 1, 0, device.vals.splice(idx, 1)[0])
            device.colors.splice(dir === 'up' ? idx - 1 : idx + 1, 0, device.colors.splice(idx, 1)[0])
            let devices = [...this.state.devices]
            devices[this.state.sel] = device
            this.setState({ devices })
            this.drawWheel()
        }
    }

    drawWheel(sel) {
        //console.log('draw wheel', sel)
        textRadius = (13 / 32 * canvasWidth)
        if (this.state.devices[isNaN(sel) ? this.state.sel : sel].vals.length < 5) {
            textRadius = textRadius - Math.round(70 / this.state.devices[isNaN(sel) ? this.state.sel : sel].vals.length)
        }

        arc = Math.PI / (parseFloat(this.state.devices[isNaN(sel) ? this.state.sel : sel].colors.length) / 2.0)
        const canvas = this.canvasRef.current
        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, 400, 400)
        ctx.strokeStyle = "black"
        ctx.lineWidth = 2
        ctx.font = 'bold 12px Helvetica, Arial'
        this.state.devices[isNaN(sel) ? this.state.sel : sel].colors.forEach((c, i) => {
            let angle = startAngle + i * arc
            ctx.fillStyle = c
            ctx.beginPath()
            ctx.arc(canvasWidth / 2, canvasHeight / 2, outsideRadius, angle, angle + arc, false)
            ctx.arc(canvasWidth / 2, canvasHeight / 2, insideRadius, angle + arc, angle, true)
            ctx.fill()
            ctx.stroke()
            ctx.fill()
            ctx.save()
            ctx.shadowOffsetX = -1
            ctx.shadowOffsetY = -1
            ctx.shadowBlur = 0
            ctx.shadowColor = "rgb(220,220,220)"
            ctx.fillStyle = "black"
            ctx.translate((canvasWidth / 2) + Math.cos(angle + arc / 2) * textRadius, (canvasHeight / 2) + Math.sin(angle + arc / 2) * textRadius)
            ctx.rotate(angle + arc / 2 + Math.PI / 2)
            let text = this.state.devices[isNaN(sel) ? this.state.sel : sel].vals[i]
            ctx.font = `bold ${(Math.round(2 * (Math.sin((360 / this.state.devices[isNaN(sel) ? this.state.sel : sel].vals.length / 2) * (Math.PI / 180)) * textRadius) / text.length * 1.1)) > 56 ? 56 : (Math.round(2 * (Math.sin((360 / this.state.devices[isNaN(sel) ? this.state.sel : sel].vals.length / 2) * (Math.PI / 180)) * textRadius) / text.length * 1.1))}px Helvetica, Arial`
            console.log(ctx.font)
            ctx.fillText(text, -ctx.measureText(text).width / 2, 0)

            ctx.restore()
            //arrow
            ctx.fillStyle = "black"
            ctx.beginPath()
            ctx.moveTo((canvasWidth / 2) - 4, (canvasHeight / 2) - (outsideRadius + 5))
            ctx.lineTo((canvasWidth / 2) + 4, (canvasHeight / 2) - (outsideRadius + 5))
            ctx.lineTo((canvasWidth / 2) + 4, (canvasHeight / 2) - (outsideRadius - 5))
            ctx.lineTo((canvasWidth / 2) + 9, (canvasHeight / 2) - (outsideRadius - 5))
            ctx.lineTo((canvasWidth / 2) + 0, (canvasHeight / 2) - (outsideRadius - 13))
            ctx.lineTo((canvasWidth / 2) - 9, (canvasHeight / 2) - (outsideRadius - 5))
            ctx.lineTo((canvasWidth / 2) - 4, (canvasHeight / 2) - (outsideRadius - 5))
            ctx.lineTo((canvasWidth / 2) - 4, (canvasHeight / 2) - (outsideRadius + 5))
            ctx.fill()
        })
    }

    render() {
        console.log(this.state)
        return <div className="Devices" style={{ backgroundImage: 'linear-gradient(to bottom right, white, navy)' }}>
            <div className="Devices-Header">My Devices</div>
            <div className="Device-Sections-Container">
                <div className="Devices-List" style={{ maxWidth: `${this.state.displayFull ? '800' : (this.state.displayMedium ? '550' : '350')}px`, minWidth: `${this.state.displayFull ? '600' : (this.state.displayMedium ? '450' : '100')}px` }}>
                    <div className="Devices-List-Header">
                        <div style={{ width: '7%', float: 'left' }}>&nbsp;</div>
                        <div className="Device-List-Header-Label">Device ID</div>
                        <div className="Device-List-Header-Label">Device Type</div>
                        <div className="Device-List-Header-Label">Forget Device</div>
                    </div>
                    <div>
                        {
                            this.state.devices.map((d, didx) => {
                                return <div key={`device-${didx}`} className="Device-List-Row">
                                    <div style={{ width: '7%', float: 'left', marginTop: '5px' }}><input type="checkbox" checked={this.state.sel === didx} onChange={() => this.handleDeviceSel(didx)} /></div>
                                    <div className="Device-List-Item" onClick={() => this.handleDeviceSel(didx)}>{d.deviceId}</div>
                                    <div className="Device-List-Item" onClick={() => this.handleDeviceSel(didx)}>{d.type}</div>
                                    <div className="Device-List-Item"><div className="Device-Button" onClick={() => this.handleDelDevice(didx)}>Release</div></div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="Device-Img">
                    <div className="Device-Container">
                        <canvas ref={this.canvasRef} width={canvasWidth} height={canvasHeight} />
                    </div>
                    {this.state.sel > -1 && <div className="Config-Container">
                        <div className="Config-Title">
                            <div className="Config-Title-Label" style={{ width: '45%' }}>Value</div>
                            <div className="Config-Title-Label" style={{ width: '25%' }}>Color</div>
                            <div className="Config-Title-Label" style={{ width: '10%' }}>Move Up</div>
                            <div className="Config-Title-Label" style={{ width: '10%' }}>Move Down</div>
                            <div className="Config-Title-Label" style={{ width: '10%' }} onClick={() => this.handleAddConfig()}>Add</div>
                        </div>
                        <div className="Config-Values">
                            {
                                this.state.devices[this.state.sel].vals.map((v, vidx) => {
                                    return <div key={`val-${vidx}`} className="Config-Values-Row">
                                        <div className="Config-Value-Text"><input style={{ width: '95%', margin: '0 auto' }} type='text' name={`device-vals-${vidx}`} value={v} onChange={event => this.handleTextInput(event)} placeholder="Display Text" /></div>
                                        <div className="Config-Value-Color"><input style={{ width: '95%', margin: '0 auto' }} type='text' name={`device-colors-${vidx}`} value={this.state.devices[this.state.sel].colors[vidx]} onChange={event => this.handleTextInput(event)} placeholder="Color Code" /></div>
                                        <div className="Config-Value-Move" onClick={() => this.handleMove('up', vidx)}><FaArrowAltCircleUp /></div>
                                        <div className="Config-Value-Move" onClick={() => this.handleMove('down', vidx)}><FaArrowCircleDown /></div>
                                        <div className="Config-Value-Delete" style={{ fontSize: '18pt' }} onClick={() => this.handleValueDelete(vidx)}><FaTrashAlt /></div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="Config-Save" onClick={() => this.handleSaveConfig()}>Save</div>
                    </div>}
                </div>
            </div>
            <div className="Devices-Header">Add Device</div>
            <div className="Device-Add-Container">
                <label htmlFor="deviceId" style={{ marginRight: '10px' }}>Device ID</label>
                <input type='text' name='deviceId' value={this.state.deviceId} onChange={event => this.handleTextInput(event)} placeholder="Enter ID from Device" />
                <div style={{ marginLeft: '10px' }} className="Device-Button" onClick={() => this.handleAddDevice(this.state.deviceId)}>Add</div>
            </div>
            {
                this.state.displayConfirm && <div className="Confirm-Container">
                    <div className="Confirm-Title">Are you sure you want to release this device?</div>
                    <div className="Confirm-Button-Row">
                        <div className="Confirm-Button" style={{ color: '#ff0000', border: '1px solid #ff0000', float: 'left', marginLeft: '10px' }} onClick={() => this.handleCancelDelDevice()}>Cancel</div>
                        <div className="Confirm-Button" style={{ color: 'rgb(33, 78, 46)', border: '1px solid rgb(33, 78, 46)', float: 'right', marginRight: '10px' }} onClick={() => this.handleDelDevice()}>Remove</div>
                    </div>
                </div>
            }
        </div>
    }
}

export default Devices