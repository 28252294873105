import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from "react-bootstrap/Nav"
import { LinkContainer } from "react-router-bootstrap"
import { withRouter } from 'react-router-dom'
import Routes from './routes'
import { Auth } from 'aws-amplify'
import './App.css'
require('dotenv').config()


class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      currUser: null,
      loadingCss: 'App-Loading-Bar-Init',
      loadedCss: 'App-Faded',
      deviceId: null
    }
  }

  async componentDidMount() {
    try {
      await Auth.currentSession()
      let currUser = await Auth.currentAuthenticatedUser()
      //      let user = await API.get('tc', `user`)
      this.setState({ loadingCss: 'App-Loading-Bar-Final', currUser })
      this.userHasAuthenticated(true)
    } catch (e) {
      if (e.message !== 'No Current User') console.log('error    ', e)
    }
    if(this.props.location.search) {
      const params = new URLSearchParams(this.props.location.search)
      if(params.get('code')) this.setState({deviceId: params.get('code')})
    } 
    setTimeout(() => this.setState({ loadingCss: 'App-Loading-Bar-Post' }), 230)
    setTimeout(() => this.setState({ isAuthenticating: false, loadedCss: 'App-Showing' }), 500)
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated })
  }

  setUser = user => { this.setState({ user }) }
  setCurrUser = currUser => { this.setState({ currUser }) }
  setWheel = deviceId => { this.setState({ deviceId }) }

  async handleLogout() {
    try {
      await Auth.signOut({ global: true })
      this.props.history.push('/login')
    } catch (e) {
      console.log(e.message)
    }
    this.setState({ user: null, currUser: null })
    this.userHasAuthenticated(false)
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setCurrUser: this.setCurrUser,
      setUser: this.setUser,
      currUser: this.state.currUser,
      deviceId: this.state.deviceId
    }

    console.log(this.state, this.props)
    return (
      !this.state.isAuthenticating ?
        <div className="py-3 container" style={{height: '100%'}}>
          <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
            <LinkContainer to="/">
              <Navbar.Brand className="font-weight-bold text-muted" bsPrefix='Nav-Brand'>
                Touchless Constructs
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle />
            {
              this.state.isAuthenticated && <Navbar.Collapse>
                <Nav activeKey={window.location.pathname}>
                  <LinkContainer to="/devices">
                    <Nav.Link>Devices</Nav.Link>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            }
            <Navbar.Collapse className="justify-content-end">
              {
                !this.state.isAuthenticated ?
                  <Nav activeKey={window.location.pathname}>
                    <LinkContainer to="/signup">
                      <Nav.Link>Register</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <Nav.Link>Sign In</Nav.Link>
                    </LinkContainer>
                  </Nav> :
                  <Nav>
                    {this.state.user && <LinkContainer to="/aboutme">
                      <Nav.Link>{`Welcome ${this.state.user.firstName || ''}`}</Nav.Link>
                    </LinkContainer>
                    }
                    <Nav.Link onClick={() => this.handleLogout()} style={{ cursor: 'pointer' }}>Log Out</Nav.Link>
                  </Nav>
              }
            </Navbar.Collapse>
          </Navbar>
          <Routes childProps={childProps} />
        </div> :
        <div className="App-Loading">
          <div className={`App-Loading-Bar ${this.state.loadingCss}`}></div>
        </div>
    )
  }
}

export default withRouter(App);
