import React from "react"
import { Route, Switch } from "react-router-dom"
import AppliedRoute from './components/appliedRoute'
import Home from "./containers/home"
import Devices from "./containers/devices"
import NotFound from './containers/notFound'
import Login from './containers/login'
import Signup from './containers/signup'

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={ Home } props={ childProps } />
    <AppliedRoute path="/devices" exact component={ Devices } props={ childProps } />
    <AppliedRoute path="/login" exact component={ Login } props={ childProps } />
    <AppliedRoute path="/signup" exact component={ Signup } props={ childProps } />
    <Route component={ NotFound } />
  </Switch>