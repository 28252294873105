import React from 'react'
import Button from "react-bootstrap/Button"
import { BsArrowRepeat } from "react-icons/bs"
import './loaderButton.css'

export default function LoaderButton({
    isLoading,
    text,
    loadingText,
    className = '',
    disabled = false,
    ...props
}) {
    return (
        <Button className={`LoaderButton ${className}`}
            disabled={disabled || isLoading}
            {...props}>
            { isLoading && <BsArrowRepeat className="spinning" />}
            { isLoading ? loadingText : text}
        </Button>
    )
}
