import React, { Component } from 'react'
import Form from "react-bootstrap/Form"
import LoaderButton from '../components/loaderButton'
import './signup.css'
import { Auth, API } from 'aws-amplify';

class Signup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            email: '',
            pw: '',
            cpw: '',
            code: '',
            user: false,
        }
    }

    formIsValid() {
        return (
            this.state.email.length > 0 &&
            this.state.pw.length > 0 && /[A-Z]/.test(this.state.pw) && /\d/.test(this.state.pw) && /\W/.test(this.state.pw) &&
            this.state.cpw === this.state.pw 
        )
    }

    codeIsValid() {
        return this.state.code.length > 0
    }

    handleChange = event => {
        switch(event.target.id) {
            case 'pw':
                this.setState({ [event.target.id]: event.target.value.replace(/[<>// ]/g, "") })
                break
            case 'cpw':
                this.setState({ [event.target.id]: event.target.value.replace(/[<>// ]/g, "") })
                break
            case 'email':
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@. ]/g, "") })
                break
            default:
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9- ]/g, "") })
        }
    }

    handleSubmit = async event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        try {
            const user = await Auth.signUp({
                username: this.state.email,
                password: this.state.pw
            })
            this.setState({ user })
        } catch (e) {
            if (e.code === 'UsernameExistsException') {
                Auth.resendSignUp(this.state.email)
                this.setState({ user: true })
            } else {
                alert(e.message)
            }
        }
        this.setState({ isLoading: false })
    }

    handleConfirmSubmit = async event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        try {
            await Auth.confirmSignUp(this.state.email, this.state.code)
            await Auth.signIn(this.state.email, this.state.pw)
            let currUser = await Auth.currentAuthenticatedUser()
            this.props.setCurrUser(currUser)
            API.post('gwh', 'user', {
                body: {
                    email: this.state.email
                }
            })
            this.props.setUser({
                email: this.state.email
            })
            this.props.userHasAuthenticated(true)
            this.props.history.push('/')
        } catch (e) {
            alert(e.message)
            this.setState({ isLoading: false })
        }
    }



    getValidation(field) {
        switch (field) {
            case 'email':
                return this.state.email.length > 0
            case 'pw':
                return this.state.pw.length > 7 && /[A-Z]/.test(this.state.pw) && /\d/.test(this.state.pw) && /\W/.test(this.state.pw)
            case 'cpw':
                return this.state.cpw.length > 7 && this.state.cpw === this.state.pw
            default:
                return true
        }
    }

    getUserForm() {
        return (
            <form onSubmit={this.handleSubmit}>                
                <Form.Group controlId="email" className="form-group-lg">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        placeholder="Email (youraddress@email.com)"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        isValid={this.getValidation('email')}
                    />
                </Form.Group>                
                <Form.Group controlId="pw" className="form-group-lg">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        placeholder="Password"
                        value={this.state.pw}
                        onChange={this.handleChange}
                        type="password"
                        isValid={this.getValidation('pw')}
                    />
                    <Form.Text className="text-muted">Password must be at least 8 characters, 1 number, 1 special character and 1 uppercase letter</Form.Text>
                </Form.Group>
                <Form.Group controlId="cpw" className="form-group-lg">
                    <Form.Label>Re-enter Password</Form.Label>
                    <Form.Control
                        placeholder="Re-enter Password"
                        value={this.state.cpw}
                        onChange={this.handleChange}
                        type="password"
                        isValid={this.getValidation('cpw')}
                    />
                </Form.Group>
                <LoaderButton
                    block
                    size="lg"
                    disabled={!this.formIsValid()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Signup"
                    loadingText="Signing up…"
                />
            </form>
        )
    }

    getConfirmForm() {
        return (
            <form onSubmit={this.handleConfirmSubmit}>
                <Form.Group controlId="code" className="form-group-lg">
                    <Form.Label>Confirmation Code</Form.Label>
                    <Form.Control
                        autoFocus
                        type="tel"
                        value={this.state.code}
                        onChange={this.handleChange}
                    />
                    <Form.Text>Please check your email for the code.</Form.Text>
                </Form.Group>
                <LoaderButton
                    block
                    size="lg"
                    disabled={!this.codeIsValid()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Verify"
                    loadingText="Verifying…"
                />
            </form>
        )
    }

    render() {
        //console.log(this.state, this.props)
        return (
            <div className="Signup">
                {this.state.user ? this.getConfirmForm() : this.getUserForm()}
            </div>
        )
    }
}

export default Signup