const config = {
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://nvnjfyi7xi.execute-api.us-east-1.amazonaws.com/prod/"
    },
    cognito: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_B4y6WTJFt',
        APP_CLIENT_ID: '77rnnvecgjbm8q0b0g0991ej2c',
        IDENTITY_POOL_ID: 'us-east-1:800062dc-abab-4768-81c7-f6c5aac37605'
    }
}

export default config
