import React, { Component } from 'react'
import Form from "react-bootstrap/Form"
import LoaderButton from '../components/loaderButton'
import { Auth, API } from 'aws-amplify'
import './login.css'

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            pw: '',
            isLoading: false
        }
    }

    formIsValid() {
        return this.state.email.length > 0 && this.state.pw.length > 0
    }

    handleSubmit = async event => {
        event.preventDefault()
        this.setState({ isLoading: true })
        try {
            await Auth.signIn(this.state.email, this.state.pw)
            let currUser = await Auth.currentAuthenticatedUser()
            //let user = await API.get('gwh', `user`)
            //this.props.setUser(user.data && user.data[0] ? user.data[0] : null)
            this.props.setCurrUser(currUser)
            this.props.userHasAuthenticated(true)
            //console.log('Success - go to main page')
            this.props.history.push('/')
        } catch (e) {
            alert(e.message)
            console.log(e)
            this.setState({ isLoading: false })
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    getValidation(field) {
        return this.state[field].length > 7
    }

    render() {
        return (
            <div className="Login">
                <form onSubmit={ this.handleSubmit }>
                    <Form.Group controlId="email" className="form-group form-group-lg">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            autoFocus
                            placeholder="Enter your email address"
                            type="email"
                            value={ this.state.email }
                            onChange={ this.handleChange }
                            isValid={ this.getValidation('email') }
                        />
                    </Form.Group>
                    <Form.Group controlId="pw" className="form-group form-group-lg">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            placeholder="Enter your password"
                            value={ this.state.pw }
                            onChange={ this.handleChange }
                            type="password"
                            isValid={ this.getValidation('pw') }
                        />
                    </Form.Group>
                    <LoaderButton
                        block
                        size="lg"
                        disabled={ !this.formIsValid() }
                        type="submit"
                        isLoading={ this.state.isLoading }
                        text="Login"
                        loadingText="Logging in..."
                    />
                </form>
            </div>
        )
    }
}

export default Login 